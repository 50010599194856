/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.css');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');
require('./main.js');

const imagesContext = require.context('../images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);

$(document).ready(function() {
    if (!!$.cookie('verify')) {
        $('.verify').addClass('off');
        // $('html, body').css({ overflow: 'auto', height: 'auto' });
    } else {
        $('.verify').removeClass('off');
        // $('html, body').css({ overflow: 'hidden', height: '100%' });
    }
    $(".cb_age").change(function() {
        if(this.checked) {
            $('.btn-age').removeClass('disabled');
        } else {
            $('.btn-age').addClass('disabled');
        }
    });
    $('.btn-age').click(function() {
        if(!$(this).hasClass('disabled')) {
            validate_age();
        }
    });

    // CHANGE LA COULEUR DU :before DES NOTES DE CUVEE
    if( $('.cuvee').length ){
        var hex = $('.cuvee').attr('data-color');
        var rgb0 = hexToRGBO(hex);
        var rgb1 = hexToRGB1(hex);
        $('head').append("<style>.owl-stage-outer:before{background: -moz-linear-gradient(left, "+ rgb1 +" 0%, "+ rgb0 +" 35%, "+ rgb0 +" 65%, "+ rgb1 +" 100%);background: -webkit-linear-gradient(left, "+ rgb1 +" 0%,"+ rgb0 +" 35%,"+ rgb0 +" 65%,"+ rgb1 +" 100%);background: linear-gradient(to right, "+ rgb1 +" 0%,"+ rgb0 +" 35%,"+ rgb0 +" 65%,"+ rgb1 +" 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 );}</style>");
    }

});
function validate_age() {
    $.cookie('verify', 'ok', { expires: 7});
    $('.verify').addClass('off');
    // $('html, body').css({ overflow: 'auto', height: 'auto' });
}




function hexToRGBO(h) {
    let r = 0, g = 0, b = 0;
    // 3 digits
    if (h.length == 4) {
      r = "0x" + h[1] + h[1];
      g = "0x" + h[2] + h[2];
      b = "0x" + h[3] + h[3];
  
    // 6 digits
    } else if (h.length == 7) {
      r = "0x" + h[1] + h[2];
      g = "0x" + h[3] + h[4];
      b = "0x" + h[5] + h[6];
    }
    return "rgba("+ +r + "," + +g + "," + +b + ",0)";
}
function hexToRGB1(h) {
    let r = 0, g = 0, b = 0;
    // 3 digits
    if (h.length == 4) {
      r = "0x" + h[1] + h[1];
      g = "0x" + h[2] + h[2];
      b = "0x" + h[3] + h[3];
  
    // 6 digits
    } else if (h.length == 7) {
      r = "0x" + h[1] + h[2];
      g = "0x" + h[3] + h[4];
      b = "0x" + h[5] + h[6];
    }
    return "rgba("+ +r + "," + +g + "," + +b + ",1)";
}