$(document).ready(function () {
    $('.burger').click(function () {
        $('.menu').toggleClass('on');
        $('.close-burger').toggleClass('on');
        $('.burger').toggleClass('on');
    });

    $('.close-burger').click(function () {
        $('.menu').toggleClass('on');
        $('.close-burger').toggleClass('on');
        $('.burger').toggleClass('on');
    });
    
    $(document).scroll(function() {
        $('.left-apparition').each(function () {
            if ($(this).length != 0 && !$(this).hasClass('on')) {
                var top_of_element = $(this).offset().top;
                var bottom_of_element = $(this).offset().top + $(this).outerHeight();
                var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                var top_of_screen = $(window).scrollTop();
        
                if (bottom_of_screen > top_of_element && top_of_screen < bottom_of_element) {
                $(this).addClass('on');
                }
            }
        });
        $('.right-apparition').each(function () {
            if ($(this).length != 0 && !$(this).hasClass('on')) {
                var top_of_element = $(this).offset().top;
                var bottom_of_element = $(this).offset().top + $(this).outerHeight();
                var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                var top_of_screen = $(window).scrollTop();
        
                if (bottom_of_screen > top_of_element && top_of_screen < bottom_of_element) {
                $(this).addClass('on');
                }
            }
        });
        scrollBanner();
    });
});

function scrollBanner() {
    scrollPos = $(this).scrollTop();
    if($('.barre').length != 0) {
        $('.barre').css({
            'top' : "-"+(200 + scrollPos / 5)+"px"
        });
    }
    if($('.paralaxx').length != 0) {
        $('.paralaxx').css({
            'background-position' : "50% "+(scrollPos / 50)+"%"
        });
    }
}

